import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../01_atoms/Link';
import ChevronRightIcon from '../../../../public/static/icons/chevron-right.svg';
import SpeakerIcon from '../../../../public/static/icons/speaker.svg';
import { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBCurrentNewsBanner = ({
  styling,
  prefix = '',
  text,
  link,
  behaviorSettings = null,
  uuid = null,
}) => {
  const classes = [
    'bb',
    styles['bb-current-news-banner'],
    styles[`bb-current-news-banner--${styling}`],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div className={classes.join(' ')} id={uuid}>
      <Link {...link.nextLink}>
        <a
          className={styles['bb-current-news-banner__link']}
          href={link.nextLink.url}
          data-title={text}
          data-category="Current news banner block"
          data-variant={styling}
        >
          <div className="container">
            <div className={styles['bb-current-news-banner__content']}>
              <div className={styles['bb-current-news-banner__icon']}>
                <SpeakerIcon />
              </div>
              <div className={styles['bb-current-news-banner__text']}>
                {prefix && (
                  <span className={styles['bb-current-news-banner__prefix']}>{prefix} </span>
                )}
                {text}
              </div>
              <div className={styles['bb-current-news-banner__arrow']}>
                <ChevronRightIcon />
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

BBCurrentNewsBanner.propTypes = {
  styling: PropTypes.oneOf(['default', 'emergency']).isRequired,
  prefix: PropTypes.string,
  text: PropTypes.string.isRequired,
  link: PropTypes.shape({
    nextLink: PropTypes.shape(linkPropTypes),
  }).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBCurrentNewsBanner;
